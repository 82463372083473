import styled from 'styled-components';
import { device } from '../../layout/device';
import colors from '../../layout/globalColors';
import { Wrapper } from '../../layout/global-style';

export const JoinCompanyWrapper = styled(Wrapper)`
  & img {
    margin-bottom: 5rem;
    max-width: 100%;
    border-radius: 1rem;
  }

  .content {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-top: 2rem;

    button {
      display: none;
      margin-top: 3rem;
    }

    p {
      line-height: 1.7;
      color: ${colors.grey};
    }
  }

  @media ${device.mobile} {
    padding-bottom: 10rem;
  }

  @media ${device.tablet} {
    .content button {
      display: inline-block;
    }
  }

  @media ${device.laptop} {
    .join-company-grid {
      display: grid;
      grid-template-columns: 5fr 6fr;
      grid-column-gap: 4rem;
      grid-row-gap: 2rem;
      align-items: center;
    }

    .content {
      font-size: 1.2rem;
      margin-top: -4rem;
    }

    .img-box {
      grid-column-start: 2;
      grid-row-start: 1;
      grid-row-end: 3;
      height: 100%;
    }

    .img-box * {
      height: 100%;
      width: 100%;
    }

    & img {
      height: 100%;
      object-fit: cover;
      margin-bottom: 0;
    }
  }

  @media ${device.laptop} {
    h2,
    .content {
      padding-right: 1.5rem;
    }

    .img-box {
      padding-left: 4rem;
    }
  }

  @media ${device.desktopS} {
    .join-company-grid {
      grid-template-columns: 4fr 6fr;
    }
    .content {
      margin-top: -4rem;
    }

    h2,
    .content {
      padding-right: 2rem;
    }
  }

  @media ${device.desktop} {
    & img {
      max-width: 60rem;
    }
  }
`;
