import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Title from '../Title';
import Button from '../Buttons';
import { CompanyServiceWrapper, Card } from './style';
import ContactForm from '../ContactUs/ContactForm';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ServiceCard = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card>
      <div className="card-content">
        <article>
          <div>{props.id}</div>
          <h3>{props.title}</h3>
          <p>{props.desc}</p>
          <Button
            onClick={handleClickOpen}
            btnText="Start a Project"
            btnType="rounded-outline"
          />
        </article>
      </div>

      <div className="card-img">
        <img src={props.img} alt={props.title} />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Start a Project</DialogTitle>
        <DialogContent>
          <ContactForm
            formType="new_project"
            askCompanyName={true}
            btnText="Send Enquiry"
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

const index = () => {
  const Services = [
    {
      id: '01',
      title: 'Mobile and Web Development',
      desc: 'We offer web development solutions for companies of all sizes, from minor to enterprise-level organizations. We specialize in matching your business needs with the best web technology for your budget. Our approach allows us to provide custom projects fueled by a deep understanding of contemporary web technologies. With us, nothing is ever cookie-cutter or templated - you get unique, personalized results every time.',
      img: '/img/optimum/Optimum-Futurist-Mobile-Development.webp',
    },
    {
      id: '02',
      title: 'Design Services',
      desc: 'Modern user interface design is a blend of both science and art. Our experienced design consultants and UX professionals have created dozens of boundary-pushing projects that consistently impress their viewers. We place a particular focus on minimalism and modernity, and all of our stylistic designs are built with the future in mind; your work will be long-lasting and professional for years to come.',
      img: '/img/optimum/Optimum-Futurist-Design-Services.webp',
    },
    {
      id: '03',
      title: 'ERP, Content Management',
      desc: "Enterprise-level problems require enterprise-level solutions, and Optimum Futurist has decades of experience architecting highly scalable systems that grow with you. Whether you're looking to track vendor accounts over time or catalog thousands of internal company resources, our custom and professional enterprise resource planning (ERP) software make it easy to stay on top of your organization and exceed expectations.",
      img: '/img/optimum/Optimum-ERP-and-CMS-Development-Services.webp',
    },
  ];
  return (
    <CompanyServiceWrapper>
      <div className="container">
        <Title title="Our Services" />

        <div className="services-container">
          {Services.map(({ id, title, desc, img }) => (
            <ServiceCard key={id} id={id} title={title} desc={desc} img={img} />
          ))}
        </div>
      </div>
    </CompanyServiceWrapper>
  );
};

ServiceCard.propTypes = {
  id: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
};

export default index;
