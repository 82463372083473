import React from 'react';

import { PageHero, DoubleImgGridAlt, DoubleImgGrid } from './style';
import Title from '../Title';

const index = (props) => {
  return (
    <PageHero>
      <div className="container">
        {props.reverseImgLayout ? (
          <DoubleImgGridAlt>
            <Title title={props.pageTitle} headingOf="page" />
            {props.children}
          </DoubleImgGridAlt>
        ) : (
          <DoubleImgGrid>
            <Title title={props.pageTitle} headingOf="page" />
            {props.children}
          </DoubleImgGrid>
        )}
      </div>
    </PageHero>
  );
};

export default index;
