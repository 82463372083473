import React from 'react';
import Layout from '../layout/layout';
import SEO from '../components/SEO';
import { ROUTES } from '../config/constants';

import HeroDoubleImg from '../components/HeroDoubleImg';
import CompanyServices from '../components/CompanyServices';
// import Team from '../components/Team';
import JoinCompany from '../components/JoinCompany';
import Achievements from '../components/Achievements';

const company = () => {
  return (
    <Layout pageTitle="Our Company">
      <SEO title={ROUTES.company.title} description={ROUTES.company.desc} />
      <HeroDoubleImg pageTitle="Our Company">
        <div className="img-group">
          <img
            src="/img/optimum/Optimum-Futurist-Nepal-Team-2021-Dec.webp"
            alt="Optimum Futurist Nepal Team 2021 Dec"
          />
          <img
            src="/img/optimum/Optimum-jenga-playing.webp"
            alt="An engineer at New Baneshwor office studying"
          />
        </div>
        <p>
          We&apos;ve quickly grown into one of the trusted names in IT
          consulting and offshoring services. Our experienced staff includes
          software engineers, DevOps engineers, and computer scientists
          specializing in the development, infrastructure optimization, and ERP
          management.
          <br />
          <br />
          Our consultants are handpicked for their industry knowledge and
          professionalism, ensuring that our clients receive only the best
          service possible.
        </p>
      </HeroDoubleImg>
      <CompanyServices />
      <JoinCompany />
      <Achievements
        title="Let’s take your digital experience to the next level"
        desc="IT isn't just something we do; our professional consultants live, eat, and breathe their work. We're committed to providing passionate, personalized service that elevates your business and improves your digital outcomes."
        highlightBorder={true}
        showStamp={false}
        showProjectRequest={false}
        hideOnMobile={true}
        maxw="44rem"
      />
    </Layout>
  );
};

export default company;
