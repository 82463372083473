import styled from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';

import { HeroWrapper } from '../Hero/style';

export const PageHero = styled(HeroWrapper)`
  min-height: auto;
  h1 {
    font-size: 2rem;
  }

  &::after {
    display: none;
  }

  @media ${device.tablet} {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  @media ${device.laptop} {
    padding: 4rem 0rem 8rem;
  }

  @media ${device.laptopL} {
    h1 {
      font-size: 3rem;
    }
  }
`;

export const DoubleImgGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: 1rem;

  .img-group {
    position: relative;
    margin-top: -2rem;
    margin-bottom: 0;
    height: 336px;

    img {
      border-radius: 1rem;
      box-shadow: 0 14px 50px rgba(0, 0, 0, 0.15);
    }

    & img:first-child {
      position: relative;
      width: 300px;
      height: 206px;
      object-fit: cover;
      margin-top: 6rem;
    }

    & img:last-child {
      position: absolute;
      top: 10px;
      right: 0;
      width: 148px;
      height: 100px;
      object-fit: cover;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 1rem;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.5rem;
      background: linear-gradient(180deg, #f4d394, #cda04b);
      box-shadow: 0 14px 50px rgba(0, 0, 0, 0.15);
      display: flex;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 40px;
      left: -64px;
      z-index: -1;
      width: 7rem;
      height: 5.25rem;
      border-radius: 1rem;
      background: linear-gradient(
        180deg,
        ${colors.spaceCadetDark},
        ${colors.spaceCadet2Dark}
      );
      box-shadow: 0 14px 50px rgba(0, 0, 0, 0.07);
    }
  }

  @media ${device.mobile} {
    & .img-group {
      width: 380px;
    }
  }

  @media ${device.tablet} {
    & .img-group {
      width: 575px;
      height: 380px;

      & img:first-child {
        width: 400px;
        height: 274px;
      }

      & img:last-child {
        width: 200px;
        height: 136px;
      }
    }
  }

  /* width >= 1024px */
  @media ${device.laptop} {
    grid-template-columns: 500px 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 0rem;

    & h1 {
      grid-row-start: 1;
      grid-row-end: 1;
    }

    & p {
      grid-column-start: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    & p + a {
      grid-column-start: 2;
      grid-row-start: 3;
      grid-row-end: 3;
    }

    & .img-group {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 1;
      display: flex;
      align-items: flex-start;
      top: 2rem;
      width: 100%;
    }

    & .img-group img:first-child {
      margin-top: 0;
    }

    & .img-group img:last-child {
      bottom: 0rem;
      top: auto;
      right: 2rem;
    }

    & .img-group::after {
      top: auto;
      left: 300px;
      bottom: -35%;
      transform: translate(-50%, -100%);
    }

    & .img-group::before {
      bottom: auto;
      top: -3rem;
      right: 4rem;
      width: 2rem;
      height: 2rem;
    }
  }

  @media ${device.laptopL} {
    & .img-group {
      top: 2rem;
    }
    & .img-group::after {
      left: 290px;
    }
  }

  @media ${device.desktopS} {
    grid-template-columns: 650px 1fr;

    h1 {
      margin-bottom: 0rem;
    }

    & .img-group {
      height: 448px;
    }

    & .img-group img:first-child {
      width: 520px;
      height: 360px;
    }

    & .img-group img:last-child {
      width: 264px;
      height: 180px;
    }

    .img-group::after {
      bottom: -28%;
      left: 430px;
    }

    .img-group::before {
      right: 6rem;
    }
  }
`;

export const DoubleImgGridAlt = styled(DoubleImgGrid)`
  & .img-group img:first-child {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 148px;
    height: 100px;
    object-fit: cover;
    margin-top: 0;
  }

  & .img-group img:last-child {
    position: relative;
    top: 0;
    margin-top: 6rem;
    width: 300px;
    height: 206px;
    object-fit: cover;
  }

  & .img-group::before {
    left: -30px;
    top: 10%;
  }

  & .img-group::after {
    left: auto;
    right: -25%;
    top: 85%;
  }

  @media ${device.tablet} {
    & .img-group img:first-child {
      width: 200px;
      height: 136px;
    }

    & .img-group img:last-child {
      width: 400px;
      height: 274px;
    }
  }

  @media ${device.laptop} {
    & .img-group {
      padding-right: 0;
      & img:first-child {
        position: absolute;
        left: 0rem;
        top: 0;
      }

      & img:last-child {
        position: absolute;
        right: 0;
        top: unset;
        bottom: 0;
      }

      &::before {
        left: auto;
        top: auto;
        bottom: -30px;
        right: 80px;
      }

      &::after {
        left: 0;
        top: 100%;
      }
    }
  }

  @media ${device.laptopL} {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 1rem;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.5rem;
      background: linear-gradient(180deg, #f4d394, #cda04b);
      box-shadow: 0 14px 50px rgba(0, 0, 0, 0.15);
      display: flex;
    }

    & .img-group {
      &::before {
        bottom: 3rem;
      }
    }
  }

  @media ${device.desktopS} {
    &::before {
      top: 40px;
      right: 160px;
    }

    .img-group::before {
      right: 140px;
      bottom: 2rem;
    }

    .img-group::after {
      left: 36px;
      top: 90%;
    }

    & .img-group img:first-child {
      width: 232px;
      height: 155px;
    }

    & .img-group img:last-child {
      width: 500px;
      height: 334px;
    }
  }
`;
