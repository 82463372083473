import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { ROUTES } from '../../config/constants';
import { JoinCompanyWrapper } from './style';
import Title from '../Title';
import { CtaLinkRoundedOutline } from '../Buttons/style';

const index = () => {
  return (
    <JoinCompanyWrapper>
      <div className="container">
        <div className="join-company-grid">
          <Title title="Join the best team of IT experts" />
          <div className="img-box">
            <StaticImage
              src="../../../static/img/optimum/Join-Optimum-Futurist.webp"
              alt="There people discussing, Join the best team of IT expert"
            />
          </div>
          <div className="content">
            <p>
              Optimum Futurist always delivers on time with uncompromising
              standards in both quality and design. We&apos;ve established a
              strong reputation as a leading provider of IT consulting because
              of our dedication to maximizing the customer experience.
            </p>
            <CtaLinkRoundedOutline to={ROUTES.career.path}>
              Join Our Team
            </CtaLinkRoundedOutline>
          </div>
        </div>
      </div>
    </JoinCompanyWrapper>
  );
};

export default index;
